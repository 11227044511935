import { ActionTree } from 'vuex';
import { AttendanceState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendance } from '@/shared/backend/attendance';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import attendance, {
  Value,
  Attendance,
  ParticipationStatusType,
  getStatusTypeText,
} from '@/shared/model/attendance';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import { SearchParams, createSearchParams } from '@/shared/model/searchParams';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.attendances');
export const actions: ActionTree<AttendanceState, RootState> = {
  getAttendancesForCheckIn(
    { commit, dispatch, getters, rootGetters },
    payload: {
      searchParams?: any;
      attendeeId?: string;
      trainerId?: string;
      locationId?: string;
    }
  ) {
    commit("setAttendancesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendancesSearchParams;
    let attendeeId = payload?.attendeeId;
    let trainerId = payload?.trainerId;

    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let currentDateTime = new Date().toISOString();

    return defaultBackendAttendance
      .getAttendancesForCheckIn(
        searchParams,
        locationId,
        currentDateTime,
        attendeeId,
        trainerId
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendances", response.data);
        commit("setAttendancesIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendancesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getAttendances(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      attendeeId?: string;
      locationId?: string;
      departmentId?: string;
      currentDateTime?: string;
      filterByCourseId?: string;
      appointmentId?: string;
      timeFrom?: string;
      timeTill?: string;
      noStore?: boolean;
      statuses?: ParticipationStatusType[];
    }
  ) {
    commit("setAttendancesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendancesSearchParams;
    let attendeeId = payload?.attendeeId ?? CONST.emptyGuid;
    let companyId = rootGetters["auth/getCompanyId"] ?? CONST.emptyGuid;
    let statuses =
      payload?.statuses != undefined
        ? payload.statuses.map((x) => getStatusTypeText(x))
        : undefined;

    return defaultBackendAttendance
      .getAttendances(
        searchParams,
        attendeeId,
        companyId,
        payload?.locationId,
        payload?.departmentId,
        payload?.currentDateTime,
        payload?.filterByCourseId,
        undefined,
        undefined,
        payload?.appointmentId,
        payload?.timeFrom,
        payload?.timeTill,
        statuses
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendancesIsLoading", false);
        if (payload?.noStore) return response.data;

        commit("setAttendances", response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendancesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getAttendancesForTrainer(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      trainerId: string;
      onlyAttendancesOfTrainer: boolean;
      locationId?: string;
      departmentId?: string;
      noStore?: boolean;
      statuses?: ParticipationStatusType[];
      currentDateTime?: string;
    }
  ) {
    commit("setAttendancesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendancesSearchParams;
    let trainerId = payload?.trainerId ?? CONST.emptyGuid;
    let companyId = rootGetters["auth/getCompanyId"] ?? CONST.emptyGuid;
    let onlyAttendancesOfTrainer = payload?.onlyAttendancesOfTrainer ?? false;
    let statuses =
      payload?.statuses != undefined
        ? payload.statuses.map((x) => getStatusTypeText(x))
        : undefined;

    return defaultBackendAttendance
      .getAttendancesForTrainer(
        searchParams,
        trainerId,
        companyId,
        onlyAttendancesOfTrainer,
        payload?.locationId,
        payload?.departmentId,
        statuses,
        payload?.currentDateTime
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendancesIsLoading", false);
        if (payload?.noStore) return response.data;

        commit("setAttendances", response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendancesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getAttendancesOfAttendeeNoStore(
    { commit, rootGetters },
    payload: { attendeeId: string; dateFrom?: string; dateTill?: string }
  ) {
    let companyId = rootGetters["auth/getCompanyId"] ?? CONST.emptyGuid;
    let searchParams: SearchParams = createSearchParams(-1);

    return defaultBackendAttendance
      .getAttendances(
        searchParams,
        payload.attendeeId,
        companyId,
        undefined,
        undefined,
        undefined,
        undefined,
        payload.dateFrom,
        payload.dateTill,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then((response: AxiosResponse<Value>) => {
        return {
          items: response.data.value.map((x) => attendance.parse(x)),
          total: response.data["@odata.count"] || 0,
        };
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getAttendance({ commit, dispatch }, id: string) {
    return defaultBackendAttendance
      .getAttendance(id)
      .then((response: AxiosResponse<Attendance>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  clearAttendances({ commit }) {
    commit("clearAttendances");
  },
  setConfirmedStatusMultiple(
    { commit, dispatch },
    payload: {
      confirmedAttendances: Attendance[];
      cancelledAttendances: Attendance[];
    }
  ) {
    commit("setAttendancesIsLoading", true);
    return defaultBackendAttendance
      .setConfirmedStatusMultiple(
        payload.confirmedAttendances,
        payload.cancelledAttendances
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          if (payload.confirmedAttendances.length > 0) {
            moduleUtils.ok(i18nGlobal.t(`success.attendances_confirm`), commit);
          } else if (payload.cancelledAttendances.length > 0) {
            moduleUtils.ok(i18nGlobal.t(`success.attendances_cancel`), commit);
          }

          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit("setAttendancesIsLoading", false);
      });
  },
  setCancelledStatusMultipleByCourse(
    { commit },
    payload: { courseId: string; attendeeId?: string }
  ) {
    commit("setAttendancesIsLoading", true);
    return defaultBackendAttendance
      .setCancelledStatusMultipleByCourse(payload.courseId, payload.attendeeId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendances_cancel`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit("setAttendancesIsLoading", false);
      });
  },
  updateAttendances({ commit, dispatch }, attendances: Attendance[]) {
    commit("setAttendancesIsLoading", true);
    return defaultBackendAttendance
      .updateAttendances(attendances)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          let attendance = attendances[0];
          if (!response.data.isValid || !response.data.result) {
            return response.data;
          }
          if (attendance) {
            if (attendance.status == ParticipationStatusType.NotConfirmed) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_booking`),
                commit
              );
            } else if (attendance.status === ParticipationStatusType.Canceled) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_cancel`),
                commit
              );
            } else if (
              attendance.status === ParticipationStatusType.Confirmed
            ) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_confirm`),
                commit
              );
            } else {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_request`),
                commit
              );
            }
          }

          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit("setAttendancesIsLoading", false);
      });
  },
  updateAttendance(
    { commit, dispatch },
    inputAttendance: Attendance & { customMessage?: string }
  ) {
    return defaultBackendAttendance
      .updateAttendance(inputAttendance)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          let attendance = response.data.result;
          if (attendance && !inputAttendance.customMessage) {
            if (attendance.status == ParticipationStatusType.NotConfirmed) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_booking`),
                commit
              );
            } else if (attendance.status === ParticipationStatusType.Canceled) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_cancel`),
                commit
              );
            } else if (
              attendance.status === ParticipationStatusType.Confirmed
            ) {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_confirm`),
                commit
              );
            } else {
              moduleUtils.ok(
                i18nGlobal.t(`success.attendances_request`),
                commit
              );
            }
          } else if (inputAttendance.customMessage) {
            moduleUtils.ok(inputAttendance.customMessage, commit);
          }

          return response.data;
        }
      )
      .catch((e: any) => {
        let errors = e?.response?.data?.errors;
        if (errors != undefined) {
          throw errors;
        } else {
          moduleUtils.error("error", commit, e, logger);
        }
      });
  },
  deleteAttendance({ commit, dispatch }, id: string) {
    return defaultBackendAttendance
      .deleteAttendance(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  cancelAttendance({ commit, dispatch }, id: string) {
    return defaultBackendAttendance
      .cancelAttendance(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendances_cancel`), commit);
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  cancelAttendanceAnonymous({ commit, dispatch }, id: string) {
    return defaultBackendAttendance
      .cancelAttendanceAnonymous(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendances_cancel`), commit);
          return response;
        }
      )
      .catch((e: any) => {
        const error = e?.response?.data?.errors?.shift() ?? "";
        if (error !== "") {
          moduleUtils.error(
            i18nGlobal.t(`error.attendances_cancel.${error}`),
            commit
          );
        } else {
          moduleUtils.error("error", commit, e, logger);
        }
        throw e;
      });
  },
  cancelAttendancesByAppointmentId(
    { commit, dispatch },
    appointmentId: string
  ) {
    return defaultBackendAttendance
      .cancelAttendanceByAppointmentId(appointmentId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendance;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendances_cancel`), commit);
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  async exportConfirmedAttendances(
    { commit },
    payload: {
      attendeeId?: string;
      fromDate: string;
      tillDate: string;
      exportAsPdf: boolean;
    }
  ) {
    commit("setAttendancesIsLoading", true);
    try {
      await defaultBackendAttendance
        .exportConfirmedAttendances(
          payload.fromDate,
          payload.tillDate,
          payload.exportAsPdf,
          payload.attendeeId,
          i18nGlobal.locale.value
        )
        .then((response) => {
          const fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    } finally {
      commit("setAttendancesIsLoading", false);
    }
  },
};
