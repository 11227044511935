import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import employee, { Employee, Value } from '../model/employee';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import { i18nGlobal } from '@/i18n';
import employeePasword, { EmployeePassword } from '../model/applicationUser';
import { AUTHORITIES } from '../store/modules/auth';

const logger = new Logger('backend.Employee');
export interface BackendEmployee {
  getRandomPassword(): AxiosPromise<string>;
  getProfileInfo: () => AxiosPromise<Employee>;
  getEmployee(id: string): AxiosPromise<Employee>;
  getEmployees: (
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    employedAfter?: boolean,
    alwaysIncludedEmployeesIds?: string[],
    includeTrainers?: boolean
  ) => AxiosPromise<Value>;
  getAllEmployees: () => AxiosPromise<Value>;
  getEmployeesAndTrainers: (searchParams: SearchParams) => AxiosPromise<Value>;
  getCountOfActiveAdminsManagers(): AxiosPromise<number>;
  deleteEmployee(id: string): AxiosPromise;
  updateEmployee(Employee: Employee): AxiosPromise<any>;
  updateProfile(
    Employee: Employee & { addContactDataChangedMembershipRequest: boolean }
  ): AxiosPromise;
  updateAuthorities(id: string, authorities: string[]): AxiosPromise;
  setNewPasswordViaEmail(employeePassword: EmployeePassword): AxiosPromise;
}

export const defaultBackendEmployee: BackendEmployee = {
  getRandomPassword(): AxiosPromise {
    return instance.get(`${URLS.employee}/GetRandomPassword`);
  },
  getProfileInfo(): AxiosPromise<Employee> {
    return instance.get<Employee>(`${URLS.employee}/GetProfileInfo`);
  },
  getEmployee(id: string): AxiosPromise<Employee> {
    let url = `${URLS.employeeOdata}/${id}`;
    return instance.get<Employee>(url);
  },
  getEmployees(
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    employedAfter?: boolean,
    alwaysIncludedEmployeesIds?: string[],
    includeTrainers?: boolean
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder("and");
    console.log("searchparams.filter: " + searchParams.filter);

    if (employedAfter != undefined) {
      let odfbEmployedAfter = ODataFilterBuilder("or");

      odfbEmployedAfter.eq(CONST.TillDate, null, false);
      odfbEmployedAfter.ge(CONST.TillDate, employedAfter, false);

      odfb.and(odfbEmployedAfter);
    }

    if (!includeTrainers) {
      odfb.ne(CONST.AuthoritiesString, AUTHORITIES.TRAINER);
    }

    let employeeIdsString: string = `[${
      alwaysIncludedEmployeesIds?.length
        ? alwaysIncludedEmployeesIds.map((id) => `'${id}'`).join(",")
        : ""
    }]`;

    let baseUrl =
      locationId != undefined
        ? `${URLS.employeeOdata}/GetInLocation(locationId=${locationId},alwaysIncludedEmployeesIds=${employeeIdsString})`
        : departmentId != undefined
        ? `${URLS.employeeOdata}/GetInDepartment(departmentId=${departmentId})`
        : `${URLS.employeeOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    url += "&$expand=Permissions";

    logger.log(`getEmployees${url}`);
    return instance.get<Value>(url);
  },

  getAllEmployees() {
    let url = DefaultBackendHelper.makeUrl(`${URLS.employee}/GetAll`);
    return instance.get<Value>(url);
  },

  getEmployeesAndTrainers(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder("and");

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.employeeOdata}/GetEmployeesAndTrainers()`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`GetEmployeesAndTrainers${url}`);
    return instance.get<Value>(url);
  },

  getCountOfActiveAdminsManagers(): AxiosPromise<number> {
    let url = `${URLS.employee}/GetCountOfActiveAdminsManagers`;
    return instance.get<number>(url);
  },

  deleteEmployee(id: string): AxiosPromise {
    logger.debug("deleteEmployee");
    return instance.delete(`${URLS.employee}/${id}`);
  },
  updateEmployee(Employee: Employee): AxiosPromise<any> {
    logger.debug("updateEmployee");

    if (Employee.id != "") {
      return instance.put<Employee>(
        `${URLS.employee}/${i18nGlobal.locale.value}`,
        employee.toAPI(Employee)
      );
    } else {
      return instance.post<Employee>(
        `${URLS.employee}/${i18nGlobal.locale.value}`,
        employee.toAPI(Employee)
      );
    }
  },
  updateProfile(
    Employee: Employee & { addContactDataChangedMembershipRequest: boolean }
  ): AxiosPromise {
    return instance.put<Employee>(
      `${URLS.employee}/UpdateProfile`,
      employee.toAPI(Employee)
    );
  },
  updateAuthorities(id: string, authorities: string[]): AxiosPromise {
    return instance.put(`${URLS.employee}/UpdateAuthorities`, {
      ApplicationUserId: id,
      Authorities: authorities.join(","),
    });
  },
  setNewPasswordViaEmail(EmployeePassword: EmployeePassword): AxiosPromise {
    return instance.post(
      `${URLS.account}/setNewPasswordViaEmail`,
      employeePasword.toAPIEmployeePassword(EmployeePassword)
    );
  },
};
