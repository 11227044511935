import { AUTHORITIES } from '../store/modules/auth';
import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';
import { i18nGlobal } from '@/i18n';
import { Salutation } from './salutation';
import { ApplicationUserPermissions, getDefaultPermissions } from './applicationUserPermissions';

export interface Employee {
  id: string;
  logo: string;
  logoId?: string;
  responsibleId?: string;
  firstName: string;
  lastName: string;
  salutation: Salutation;
  userName: string;
  locations: string;
  title: string;
  email: string;
  phone: string;
  text: string;
  role: string;
  nr: number;
  zip: string;
  street: string;
  city: string;
  isRegistrated: boolean;
  isIntern: boolean;
  isOnline: boolean;
  authorities: string[];
  permissions: ApplicationUserPermissions;
  readonly authoritiesString: string;
  readonly isActualEmployee: boolean;
  active: boolean;
  birthday: string;
  fromDate: string;
  tillDate: string;
  lastLoginDate: string;
  createdDate: string;
  password: string;
  departmentNames: string;
  readonly isInactiveOrExpired: boolean;
  readonly fullName: string;
  unlocked: boolean;
}

export interface Value {
  value: Employee[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(
  data: Partial<Employee & { addContactDataChangedMembershipRequest: boolean }>
): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    ResponsibleId: data?.responsibleId || undefined,
    FirstName: data?.firstName || "",
    LastName: data?.lastName || "",
    Salutation: data?.salutation || "",
    UserName: data?.userName || "",
    Title: data?.title || "",
    Email: data?.email || "",
    Phone: data?.phone || "",
    Text: data?.text || "",
    Zip: data?.zip || "",
    Street: data?.street || "",
    City: data?.city || "",
    IsRegistrated: data?.isRegistrated || false,
    IsIntern: data?.isIntern || false,
    Authorities: data?.authorities?.join(",") || "",
    Active: data?.active || false,
    Birthday: DateUtils.dateNullableToApi(data?.birthday),
    FromDate: data?.fromDate || false,
    TillDate: DateUtils.dateNullableToApi(data?.tillDate),
    Password: data?.password || undefined,
    Unlocked: data?.unlocked ?? false,
    AddContactDataChangedMembershipRequest: data?.addContactDataChangedMembershipRequest ?? false,
  };
}

function parse(data?: Partial<Employee>): Employee {
  return {
    id: data?.id || '',
    logo: data?.logo || '',
    logoId: data?.logoId || '',
    responsibleId: data?.responsibleId || undefined,
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    salutation: data?.salutation || '',
    locations: data?.locations || '',
    title: data?.title || '',
    phone: data?.phone || '',
    email: data?.email || '',
    role: data?.role || '',
    userName: data?.userName || '',
    text: data?.text || '',
    nr: data?.nr || 0,
    zip: data?.zip || '',
    street: data?.street || '',
    city: data?.city || '',
    isRegistrated: data?.isRegistrated || false,
    isOnline: data?.isOnline || false,
    isIntern: data?.isIntern !== undefined ? data.isIntern : true,
    authorities: data?.authorities || [],
    get authoritiesString() {
      return this.authorities
        .map((x) => i18nGlobal.t(`app.authorities.${x}`))
        .join(', ');
    },
    permissions: data?.permissions || getDefaultPermissions(data?.authorities || []),
    get isActualEmployee() {
      return (
        this.authorities.includes(AUTHORITIES.EMPLOYEE) ||
        this.authorities.includes(AUTHORITIES.MANAGER) ||
        this.authorities.includes(AUTHORITIES.ADMIN)
      );
    },
    active: data?.active || false,
    birthday: DateUtils.parseDateFromBackend(data?.birthday),
    fromDate: DateUtils.parseDateFromBackend(
      data?.fromDate,
      DateUtils.getTodayDateAsIsoString()
    ),
    tillDate: DateUtils.parseDateFromBackend(data?.tillDate),
    createdDate: data?.createdDate || '',
    lastLoginDate: data?.lastLoginDate || '',
    password: '',
    departmentNames: data?.departmentNames || '',
    get isInactiveOrExpired(): boolean {
      let todayDate = DateUtils.getTodayDateAsIsoString();
      return (
        !this.active ||
        (this.tillDate != '' && this.tillDate < todayDate) ||
        this.fromDate > todayDate
      );
    },
    get fullName() {
      return `${this.lastName}, ${this.firstName}`;
    },
    unlocked: data?.unlocked ?? false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};

export const EmptyEmployee = parse({
  authorities: [AUTHORITIES.EMPLOYEE],
});
